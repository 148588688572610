import React, { Component, useEffect, useState } from 'react';
import { bool, func, object, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import {
  Form,
  Button,
  FieldCheckboxGroup,
  DatePicker,
  FieldCurrencyInput,
  IconClose,
  FieldTextInput,
} from '../../components';
import * as validators from '../../util/validators';
import ManageAvailabilityCalendar from './ManageAvailabilityCalendar';
import css from './EditListingAvailabilityForm.module.css';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';
import { AiFillCloseCircle } from 'react-icons/ai';
import { IoMdAddCircle } from 'react-icons/io';
import { RxCross2 } from 'react-icons/rx';
import moment from 'moment';
import config from '../../config';
import { LINE_ITEM_NIGHT, LINE_ITEM_DAY } from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';
const { Money } = sdkTypes;
import { formatMoney } from '../../util/currency';
export class EditListingAvailabilityFormComponent extends Component {
  render() {
    return (
      <FinalForm
        {...this.props}
        mutators={{ ...arrayMutators }}
        render={formRenderProps => {
          const {
            className,
            rootClassName,
            disabled,
            ready,
            handleSubmit,
            intl,
            invalid,
            pristine,
            saveActionMsg,
            updated,
            updateError,
            updateInProgress,
            availability,
            availabilityPlan,
            listingId,
            publicData,
            exceptionView,
            listing,
            values,
          } = formRenderProps;
          const unitType = config.bookingUnitType;
          const isNightly = unitType === LINE_ITEM_NIGHT;
          const isDaily = unitType === LINE_ITEM_DAY;
          const [maximumStay, setMaximumStay] = useState(false);
          useEffect(() => {
            const minStay = values['min_stay'];
            const maxStay = values['max_stay'];
            if (!minStay) {
              setMaximumStay(false);
              return;
            }
            if (!maxStay) {
              setMaximumStay(false);
              return;
            }
            if (values?.min_stay && values?.max_stay) {
              if (+values?.min_stay > +values?.max_stay) {
                setMaximumStay(true);
              } else {
                setMaximumStay(false);
              }
            }
          }, [values.min_stay, values.max_stay]);
          const translationKey = isNightly
            ? 'EditListingPricingForm.pricePerNight'
            : isDaily
            ? 'EditListingPricingForm.pricePerDay'
            : 'EditListingPricingForm.pricePerUnit';

          const pricePerUnitMessage = intl.formatMessage({
            id: translationKey,
          });

          const pricePlaceholderMessage = intl.formatMessage({
            id: 'EditListingPricingForm.priceInputPlaceholder',
          });

          const priceRequired = validators.required(
            intl.formatMessage({
              id: 'EditListingPricingForm.priceRequired',
            })
          );
          const minPrice = new Money(config.listingMinimumPriceSubUnits, config.currency);
          const minPriceRequired = validators.moneySubUnitAmountAtLeast(
            intl.formatMessage(
              {
                id: 'EditListingPricingForm.priceTooLow',
              },
              {
                minPrice: formatMoney(intl, minPrice),
              }
            ),
            config.listingMinimumPriceSubUnits
          );
          const priceValidators = config.listingMinimumPriceSubUnits
            ? validators.composeValidators(priceRequired, minPriceRequired)
            : priceRequired;
          const errorMessage = updateError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingAvailabilityForm.updateFailed" />
            </p>
          ) : null;

          const classes = classNames(rootClassName || css.root, className);
          const submitReady = (updated && pristine) || ready;
          const submitInProgress = updateInProgress;
          const submitDisabled = invalid || disabled || submitInProgress || maximumStay;

          return (
            <Form className={classes} onSubmit={handleSubmit}>
              {errorMessage}
              <div className={css.calendarWrapper}>
                <ManageAvailabilityCalendar
                  availability={availability}
                  availabilityPlan={availabilityPlan}
                  listingId={listingId}
                  publicData={publicData}
                  intl={intl}
                  listing={listing}
                />
              </div>
              {exceptionView}
              <div className={css.datePriceArrayContainer}>
                <p className={css.note}>
                  {intl.formatMessage({
                    id: 'EditListingAvailabilityForm.EditListingAvailabilityForm.noteOne',
                  })}
                </p>
                <label className={css.label} for="datePriceArray">
                  <FormattedMessage id="EditListingPricingForm.datePriceLabel" />
                </label>
                {values?.datePriceArray?.length < 1 ? (
                  <p className={css.noPrice}>
                    {intl.formatMessage({
                      id:
                        'EditListingAvailabilityForm.EditListingAvailabilityForm.noSpecificDayLabel',
                    })}
                  </p>
                ) : null}

                <FieldArray name="datePriceArray">
                  {({ fields }) => (
                    <div className={css.datePriceArray}>
                      {fields.map((name, index) => (
                        <div key={index} className={css.datePriceContainer}>
                          <div className={css.experienceCloseContainer}>
                            <span onClick={() => fields.remove(index)}>
                              <IconClose size="normal" className={css.close} />
                            </span>
                          </div>
                          <div className={css.datePriceContent}>
                            <DatePicker
                              className={css.date}
                              id={`${name}.start_date`}
                              name={`${name}.start_date`}
                              label={intl.formatMessage({
                                id:
                                  'EditListingAvailabilityForm.EditListingAvailabilityForm.startDateLabel',
                              })}
                              placeholder={intl.formatMessage({
                                id:
                                  'EditListingAvailabilityForm.EditListingAvailabilityForm.startDatePlaceholder',
                              })}
                              minDate={new Date()}
                              // minDate={new Date()}
                              validate={validators.composeValidators(
                                validators.required(
                                  intl.formatMessage({
                                    id: 'EditListingPricingForm.PriceDateRequired',
                                  })
                                )
                              )}
                            />
                            <DatePicker
                              className={css.date}
                              id={`${name}.end_date`}
                              name={`${name}.end_date`}
                              minDate={
                                values?.datePriceArray[index]?.start_date
                                  ? values?.datePriceArray[index]?.start_date
                                  : new Date()
                              }
                              label={intl.formatMessage({
                                id:
                                  'EditListingAvailabilityForm.EditListingAvailabilityForm.enddateLabel',
                              })}
                              placeholder={intl.formatMessage({
                                id:
                                  'EditListingAvailabilityForm.EditListingAvailabilityForm.endDatePlaceholder',
                              })}

                              // minDate={new Date()}
                              // validate={validators.composeValidators(
                              //   validators.required(
                              //     intl.formatMessage({
                              //       id: 'EditListingPricingForm.PriceDateRequired',
                              //     })
                              //   )
                              // )}
                            />
                            <FieldCurrencyInput
                              id={`${name}.price`}
                              name={`${name}.price`}
                              className={css.price}
                              label={pricePerUnitMessage}
                              placeholder={pricePlaceholderMessage}
                              currencyConfig={config.currencyConfig}
                              validate={priceValidators}
                              required={true}
                            />
                          </div>
                        </div>
                      ))}

                      <Button
                        className={css.addButton}
                        type="button"
                        onClick={() => fields.push({ date: '', price: {} })}
                      >
                        {/* <IoMdAddCircle /> */}
                        <FormattedMessage id="EditListingPricingForm.addDateButton" />

                        {/* {addMessage} */}
                      </Button>
                      <p className={css.specificPriceNote}>
                        <FormattedMessage id="EditListingAvailabilityForm.EditListingAvailabilityForm.specificPriceNote" />
                      </p>
                    </div>
                  )}
                </FieldArray>
                {/* <p className={css.note}>
                  {intl.formatMessage({
                    id: 'EditListingAvailabilityForm.EditListingAvailabilityForm.noteTwo',
                  })}
                </p> */}
              </div>
              <div>
                <FieldTextInput
                  id="buffer_time"
                  name="buffer_time"
                  className={css.bufferTime}
                  label={intl.formatMessage({
                    id: 'EditListingAvailabilityForm.EditListingAvailabilityForm.bufferTimeLabel',
                  })}
                  placeholder={intl.formatMessage({
                    id:
                      'EditListingAvailabilityForm.EditListingAvailabilityForm.bufferTimePlaceholder',
                  })}
                  type="number"
                />
                <FieldTextInput
                  id="min_stay"
                  name="min_stay"
                  className={css.bufferTime}
                  label={intl.formatMessage({
                    id: 'EditListingAvailabilityForm.EditListingAvailabilityForm.minStayLabel',
                  })}
                  placeholder={intl.formatMessage({
                    id:
                      'EditListingAvailabilityForm.EditListingAvailabilityForm.minStayPlaceholder',
                  })}
                  type="number"
                />
                <FieldTextInput
                  id="max_stay"
                  name="max_stay"
                  className={css.bufferTime}
                  label={intl.formatMessage({
                    id: 'EditListingAvailabilityForm.EditListingAvailabilityForm.maxStayLabel',
                  })}
                  placeholder={intl.formatMessage({
                    id:
                      'EditListingAvailabilityForm.EditListingAvailabilityForm.maxStayPlaceholder',
                  })}
                  type="number"
                />
                {maximumStay ? (
                  <p className={css.error}>
                    {intl.formatMessage({
                      id:
                        'EditListingAvailabilityForm.EditListingAvailabilityForm.maximumStayValidation',
                    })}
                  </p>
                ) : null}
              </div>
              {/* {exceptionView} */}
              <Button
                className={css.submitButton}
                type="submit"
                inProgress={submitInProgress}
                disabled={submitDisabled}
                ready={submitReady}
              >
                {saveActionMsg}
              </Button>
            </Form>
          );
        }}
      />
    );
  }
}

EditListingAvailabilityFormComponent.defaultProps = {
  updateError: null,
};

EditListingAvailabilityFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateError: propTypes.error,
  updateInProgress: bool.isRequired,
  availability: object.isRequired,
  availabilityPlan: propTypes.availabilityPlan.isRequired,
};

export default compose(injectIntl)(EditListingAvailabilityFormComponent);
