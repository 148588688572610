import React from 'react';
import { string } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { twitterPageURL } from '../../util/urlHelpers';
import config from '../../config';
import {
  IconSocialMediaFacebook,
  IconSocialMediaInstagram,
  IconSocialMediaTwitter,
  Logo,
  ExternalLink,
  NamedLink,
} from '../../components';
import { ImFacebook } from 'react-icons/im';
import { AiFillYoutube, AiOutlineTwitter, AiFillInstagram, AiFillLinkedin } from 'react-icons/ai';

import css from './FooterUI.module.css';

const renderSocialMediaLinks = intl => {
  const {
    siteFacebookPage,
    siteInstagramPage,
    siteTwitterHandle,
    siteLinkedinPage,
    siteYoutubePage,
  } = config;
  const siteTwitterPage = twitterPageURL(siteTwitterHandle);

  const goToFb = intl.formatMessage({ id: 'FooterUI.goToFacebook' });
  const goToInsta = intl.formatMessage({ id: 'FooterUI.goToInstagram' });
  const goToTwitter = intl.formatMessage({ id: 'FooterUI.goToTwitter' });
  const goToLinkedin = intl.formatMessage({ id: 'FooterUI.goToLinkedin' });
  const goToYoutube = intl.formatMessage({ id: 'FooterUI.goToYoutube' });

  const fbLink = siteFacebookPage ? (
    <ExternalLink
      target="_blank"
      key="linkToFacebook"
      href={siteFacebookPage}
      className={css.icon}
      title={goToFb}
    >
      <ImFacebook className={css.iconReal} />
    </ExternalLink>
  ) : null;

  const twitterLink = siteTwitterPage ? (
    <ExternalLink
      target="_blank"
      key="linkToTwitter"
      href={siteTwitterPage}
      className={css.icon}
      title={goToTwitter}
    >
      <AiOutlineTwitter className={css.iconReal} />
    </ExternalLink>
  ) : null;

  const instragramLink = siteInstagramPage ? (
    <ExternalLink
      target="_blank"
      key="linkToInstagram"
      href={siteInstagramPage}
      className={css.icon}
      title={goToInsta}
    >
      <AiFillInstagram className={css.iconReal} />
    </ExternalLink>
  ) : null;
  const linkedinLink = siteLinkedinPage ? (
    <ExternalLink
      key="linkToLinkedin"
      href={siteLinkedinPage}
      className={css.icon}
      target="_blank"
      title={goToLinkedin}
    >
      <AiFillLinkedin className={css.iconReal} />
    </ExternalLink>
  ) : null;
  const youtubeLink = siteYoutubePage ? (
    <ExternalLink
      key="linkToYoutube"
      href={siteYoutubePage}
      target="_blank"
      className={css.icon}
      title={goToYoutube}
    >
      <AiFillYoutube className={css.iconReal} />
    </ExternalLink>
  ) : null;
  return [fbLink, youtubeLink, twitterLink, instragramLink, linkedinLink].filter(v => v != null);
};

const FooterUI = props => {
  const { rootClassName, className, intl } = props;
  const socialMediaLinks = renderSocialMediaLinks(intl);
  const classes = classNames(rootClassName || css.root, className);
  let cookieValue;
  if (typeof window !== 'undefined') {
    cookieValue =
      document.cookie.match('(^|;)\\s*' + 'language' + '\\s*=\\s*([^;]+)')?.pop() || 'english';
  }
  return (
    <div className={classes}>
      <div className={css.content}>
        <div className={css.linksLeftContainer}>
          <NamedLink name="TermsOfServicePage" className={css.eachLink}>
            <FormattedMessage id="FooterUI.FooterUI.tosLinkLabel" />{' '}
          </NamedLink>
          <NamedLink name="PrivacyPolicyPage" className={css.eachLink}>
            <FormattedMessage id="FooterUI.FooterUI.privacyPolicyLinkLabel" />
          </NamedLink>
          <NamedLink name="AboutPage" className={css.eachLink}>
            <FormattedMessage id="FooterUI.FooterUI.faqLinkLabel" />
          </NamedLink>
          {/* <ExternalLink
            href={
              cookieValue === 'english'
                ? 'https://community.lingobnb.com/'
                : 'https://community.lingobnb.com/inicio/'
            }
            className={css.eachLink}
          >
            <FormattedMessage id="FooterUI.FooterUI.communityLinkLabel" />
          </ExternalLink> */}
          <NamedLink name="ContactUsPage" className={css.eachLink}>
            <FormattedMessage id="FooterUI.FooterUI.contactUsLabel" />
          </NamedLink>
        </div>
        <div className={css.linksSocialContainer}>{socialMediaLinks}</div>
      </div>
    </div>
  );
};

FooterUI.defaultProps = {
  rootClassName: null,
  className: null,
};

FooterUI.propTypes = {
  rootClassName: string,
  className: string,
  intl: intlShape.isRequired,
};

export default injectIntl(FooterUI);
