import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { Button, NamedLink } from '../../components';

import css from './SectionWhatMakes.module.css';
import bookImage from './images/book.png';
import calendarImage from './images/calendar.png';
import cupImage from './images/cup.png';
import caseImage from './images/case.png';
import paintImage from './images/paint.png';
import globeImage from './images/globe.png';
import { useIntl } from 'react-intl';
const EachStep = ({ image, logo, title, content }) => {
  if (image)
    return (
      <div className={css.eachStepImage}>
        {' '}
        <img src={image}></img>{' '}
      </div>
    );
  return (
    <div className={css.eachStep}>
      {logo}
      <h3 className={css.subTitle}>{title}</h3>
      <p className={css.text}>{content}</p>
    </div>
  );
};
const SectionWhatMakes = props => {
  const { rootClassName, className } = props;
  const intl = useIntl();
  const classes = classNames(rootClassName || css.root, className);
  const data = [
    {
      image: globeImage,
      title: intl.formatMessage({ id: 'SectionWhatMakes.SectionWhatMakes.titleOne' }),
      text: (
        <FormattedMessage
          id="SectionWhatMakes.SectionWhatMakes.textOne"
          values={{ b: msg => <b className={css.bold}>{msg}</b> }}
        />
      ),
    },
    {
      image: paintImage,
      title: intl.formatMessage({ id: 'SectionWhatMakes.SectionWhatMakes.titleTwo' }),
      text: (
        <FormattedMessage
          id="SectionWhatMakes.SectionWhatMakes.textTwo"
          values={{ b: msg => <b className={css.bold}>{msg}</b> }}
        />
      ),
    },
    {
      image: calendarImage,
      title: intl.formatMessage({ id: 'SectionWhatMakes.SectionWhatMakes.titleThree' }),
      text: (
        <FormattedMessage
          id="SectionWhatMakes.SectionWhatMakes.textThree"
          values={{ b: msg => <b className={css.bold}>{msg}</b> }}
        />
      ),
    },
    {
      image: caseImage,
      title: intl.formatMessage({ id: 'SectionWhatMakes.SectionWhatMakes.titleFour' }),
      text: (
        <FormattedMessage
          id="SectionWhatMakes.SectionWhatMakes.textFour"
          values={{ b: msg => <b className={css.bold}>{msg}</b> }}
        />
      ),
    },
    {
      image: calendarImage,
      title: intl.formatMessage({ id: 'SectionWhatMakes.SectionWhatMakes.titleFive' }),
      text: (
        <FormattedMessage
          id="SectionWhatMakes.SectionWhatMakes.textFive"
          values={{ b: msg => <b className={css.bold}>{msg}</b> }}
        />
      ),
    },
  ];
  return (
    <div className={classes}>
      <div className={css.title}>
        <FormattedMessage id="SectionWhatMakes.SectionWhatMakes.title" />
      </div>
      <h3 className={css.subTitleText}>
        <FormattedMessage id="SectionWhatMakes.SectionWhatMakes.subTitleText" />
      </h3>
      <div className={css.stepsContainer}>
        {data.map((item, index) => (
          <EachStep
            className={css.eachStep}
            logo={<img className={css.logo} src={item.image} />}
            title={item.title}
            content={item.text}
          />
        ))}
        {/* <div className={css.eachStep}>
          <img className={css.image} src={globeImage}></img>
          <p className={css.content}>
            <FormattedMessage id="SectionWhatMakes.SectionWhatMakes.1p1" />{' '}
            <b>
              {' '}
              <FormattedMessage id="SectionWhatMakes.SectionWhatMakes.1b" />
            </b>{' '}
            <FormattedMessage id="SectionWhatMakes.SectionWhatMakes.1p2" />
          </p>
        </div>
        <div className={css.eachStep}>
          <img className={css.image} src={paintImage}></img>
          <p className={css.content}>
            <FormattedMessage id="SectionWhatMakes.SectionWhatMakes.2p1" />
            <b>
              {' '}
              <FormattedMessage id="SectionWhatMakes.SectionWhatMakes.2b" />
            </b>{' '}
            <FormattedMessage id="SectionWhatMakes.SectionWhatMakes.2p2" />
          </p>
        </div>
        <div className={css.eachStep}>
          <img className={css.image} src={calendarImage}></img>
          <p className={css.content}>
            <FormattedMessage id="SectionWhatMakes.SectionWhatMakes.3p1" />{' '}
            <b>
              <FormattedMessage id="SectionWhatMakes.SectionWhatMakes.3b" />
            </b>{' '}
            <FormattedMessage id="SectionWhatMakes.SectionWhatMakes.3p2" />
          </p>
        </div>
        <div className={css.eachStep}>
          <img className={css.image} src={cupImage}></img>
          <p className={css.content}>
            <b>
              <FormattedMessage id="SectionWhatMakes.SectionWhatMakes.4b" />
            </b>{' '}
            <FormattedMessage id="SectionWhatMakes.SectionWhatMakes.4p1" />
          </p>
        </div>
        <div className={css.eachStep}>
          <img className={css.image} src={bookImage}></img>
          <p className={css.content}>
            <FormattedMessage id="SectionWhatMakes.SectionWhatMakes.5p1" />{' '}
            <b>
              <FormattedMessage id="SectionWhatMakes.SectionWhatMakes.5b" />
            </b>{' '}
            <FormattedMessage id="SectionWhatMakes.SectionWhatMakes.5p2" />
          </p>
        </div>
        <div className={css.eachStep}>
          <img className={css.image} src={caseImage}></img>
          <p className={css.content}>
            <FormattedMessage id="SectionWhatMakes.SectionWhatMakes.6p1" />{' '}
            <b>
              <FormattedMessage id="SectionWhatMakes.SectionWhatMakes.6b" />
            </b>{' '}
            <FormattedMessage id="SectionWhatMakes.SectionWhatMakes.6p2" />
          </p>
        </div> */}
      </div>
    </div>
  );
};

SectionWhatMakes.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionWhatMakes.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionWhatMakes;
