/**
 * Renders non-reversal line items that are not listed in the
 * `LINE_ITEMS` array in util/types.js
 *
 * The line items are rendered so that the line item code is formatted to human
 * readable form and the line total is printed as price.
 *
 * If you require another kind of presentation for your line items, add them to
 * the `LINE_ITEMS` array in util/types.js and create a specific line item
 * component for them that can be used in the `BookingBreakdown` component.
 */
import React from 'react';
import { intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { humanizeLineItemCode } from '../../util/data';
import { LINE_ITEMS, propTypes } from '../../util/types';
import Popup from 'reactjs-popup';
import css from './BookingBreakdown.module.css';
import { getFormattedMoney } from './LineItemCustomerBasePriceMaybe';
import { types as sdkTypes } from '../../util/sdkLoader';
import { FaCircleInfo } from 'react-icons/fa6';
const { Money } = sdkTypes;
const LineItemUnknownItemsMaybe = props => {
  const { transaction, isProvider, intl } = props;

  // resolve unknown non-reversal line items
  const allItems = transaction.attributes.lineItems.filter(
    item => LINE_ITEMS.indexOf(item.code) === -1 && !item.reversal
  );
  const { attributes } = transaction || {};
  const { protectedData } = attributes || {};
  const { extraLineItems } = protectedData || {};
  const extraItems = allItems?.find(item => item?.extra);
  const { extra, code } = extraLineItems || extraItems || {};

  const items = isProvider
    ? allItems.filter(item => item.includeFor.includes('provider'))
    : allItems.filter(item => item.includeFor.includes('customer'));

  return items.length > 0 ? (
    <React.Fragment>
      {items.map((item, i) => {
        const quantity = item.quantity;

        const label =
          quantity && quantity > 1
            ? `${humanizeLineItemCode(item.code)} x ${quantity} h`
            : humanizeLineItemCode(item.code);

        const formattedTotal = getFormattedMoney(intl, item.lineTotal, item.code, isProvider);
        return (
          <div key={`${i}-item.code`} className={css.lineItem}>
            <span className={css.itemLabel}>{label}</span>
            <span className={css.itemValue}>
              {formattedTotal}
              {extra && extra.length > 0 && code === item.code ? (
                <Popup
                  trigger={open => (
                    <button className={css.infoPopup} type="button">
                      <FaCircleInfo className={css.icon} />
                    </button>
                  )}
                  position="left center"
                  closeOnDocumentClick
                >
                  <div className={css.container}>
                    {extra.map((item, i) => (
                      <div key={`${i}-item.code`} className={css.lineItem}>
                        <span className={css.itemLabel}>{item.date}</span>
                        <span className={css.itemValue}>
                          {getFormattedMoney(
                            intl,
                            new Money(item.price, 'EUR'),
                            item.code,
                            isProvider
                          )}
                          {/* {formatMoney(intl, new Money(item.price, 'EUR'))} */}
                        </span>
                      </div>
                    ))}
                  </div>
                </Popup>
              ) : null}
            </span>
          </div>
        );
      })}
    </React.Fragment>
  ) : null;
};

LineItemUnknownItemsMaybe.propTypes = {
  transaction: propTypes.transaction.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemUnknownItemsMaybe;
