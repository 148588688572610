import React, { useState, useEffect } from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import {
  Form,
  Button,
  FieldTextInput,
  FieldSelectModern,
  FieldCheckboxGroup,
  FieldInputCounter,
  FieldTagInput,
} from '../../components';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';
import css from './EditListingPoliciesForm.module.css';
import config from '../../config';
import {
  composeValidators,
  required,
  requiredArrayItems,
  requiredFieldArrayCheckbox,
} from '../../util/validators';
import { AiTwotoneDelete } from 'react-icons/ai';
export const EditListingPoliciesFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={formRenderProps => {
      const {
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        category,
        values,
      } = formRenderProps;
      // console.log('values', values);
      const [lessonValidation, setLessonValidation] = useState(false);
      useEffect(() => {
        const minStay = values['min_lessons'];
        const maxStay = values['lessons'];
        if (!minStay) {
          setLessonValidation(false);
          return;
        }
        if (!maxStay) {
          setLessonValidation(false);
          return;
        }
        if (values?.min_lessons && values?.lessons) {
          if (+values?.min_lessons > +values?.lessons) {
            setLessonValidation(true);
          } else {
            setLessonValidation(false);
          }
        }
      }, [values.min_lessons, values.lessons]);
      const activitiesPlaceholder = intl.formatMessage({
        id: 'EditListingPoliciesForm.activitiesPlaceholder',
      });

      const rulesPlaceholderMessage = intl.formatMessage({
        id: 'EditListingPoliciesForm.rulesPlaceholder',
      });

      const { updateListingError, showListingsError } = fetchErrors || {};
      const errorMessage = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingPoliciesForm.updateFailed" />
        </p>
      ) : null;
      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingPoliciesForm.showListingFailed" />
        </p>
      ) : null;

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress || lessonValidation;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessage}
          {errorMessageShowListing}
          <FieldArray name="languages_spoken">
            {({ fields }) => (
              <div>
                <h3 className={css.languageHeading}>
                  {intl.formatMessage({
                    id: 'EditListingPoliciesForm.EditListingPoliciesForm.languageSpokenLabel',
                  })}
                </h3>
                {fields.map((name, index) => (
                  <div key={name} className={css.fieldArrayContainer}>
                    <FieldSelectModern
                      className={css.language}
                      id={`${name}.language`}
                      name={`${name}.language`}
                      placeholder={intl.formatMessage({
                        id:
                          'EditListingPoliciesForm.EditListingPoliciesForm.languageSpokenPlaceholder',
                      })}
                      options={config.custom.languageOptions}
                      validate={required(
                        <FormattedMessage id="EditListingPoliciesForm.EditListingPoliciesForm.languageSpokenReq" />
                      )}
                    />
                    <div className={css.subContainer}>
                      <FieldSelectModern
                        className={css.level}
                        id={`${name}.level`}
                        name={`${name}.level`}
                        placeholder={intl.formatMessage({
                          id: 'EditListingPoliciesForm.EditListingPoliciesForm.levelPlaceholder',
                        })}
                        options={config.custom.levelOptions}
                        validate={required(
                          <FormattedMessage id="EditListingPoliciesForm.EditListingPoliciesForm.levelRequiredMessage" />
                        )}
                      />
                      {values?.languages_spoken?.length > 1 && (
                        <p className={css.close} onClick={() => fields.remove(index)}>
                          <AiTwotoneDelete className={css.delete} />
                        </p>
                      )}
                    </div>
                  </div>
                ))}
                <p
                  className={css.addAnotherLanguage}
                  onClick={() => fields.push({ language: '', level: '' })}
                >
                  {intl.formatMessage({
                    id: 'EditListingPoliciesForm.EditListingPoliciesForm.addAnotherLanguageLabel',
                  })}
                </p>
              </div>
            )}
          </FieldArray>
          <FieldArray name="languages_taught">
            {({ fields }) => (
              <div>
                <h3 className={css.languageHeading}>
                  {intl.formatMessage({
                    id: 'EditListingPoliciesForm.EditListingPoliciesForm.languageTaughtLabel',
                  })}
                </h3>
                {fields.map((name, index) => (
                  <div key={name} className={css.fieldArrayContainer}>
                    <FieldSelectModern
                      className={css.language}
                      id={`${name}.language`}
                      name={`${name}.language`}
                      placeholder={intl.formatMessage({
                        id:
                          'EditListingPoliciesForm.EditListingPoliciesForm.languageTaughtPlaceholder',
                      })}
                      options={config.custom.languageOptions}
                      validate={required(
                        <FormattedMessage id="EditListingPoliciesForm.EditListingPoliciesForm.languageTaughtReq" />
                      )}
                    />
                    <div className={css.subContainer}>
                      <FieldSelectModern
                        className={css.level}
                        id={`${name}.level`}
                        name={`${name}.level`}
                        isMulti={true}
                        placeholder={intl.formatMessage({
                          id:
                            'EditListingPoliciesForm.EditListingPoliciesForm.levelTaughtPlaceholder',
                        })}
                        options={config.custom.levelOptions?.filter(i => i.key !== 'Native')}
                        validate={required(
                          <FormattedMessage id="EditListingPoliciesForm.EditListingPoliciesForm.levelTaughtRequired" />
                        )}
                      />
                      {values?.languages_taught?.length > 1 && (
                        <p className={css.close} onClick={() => fields.remove(index)}>
                          <AiTwotoneDelete className={css.delete} />
                        </p>
                      )}
                    </div>
                  </div>
                ))}
                <p
                  className={css.addAnotherLanguage}
                  onClick={() => fields.push({ language: '', level: '' })}
                >
                  {intl.formatMessage({
                    id: 'EditListingPoliciesForm.EditListingPoliciesForm.addAnotherLanguageTaught',
                  })}
                </p>
              </div>
            )}
          </FieldArray>
          <FieldInputCounter
            className={css.policy}
            id="yearsOfExperience"
            name="yearsOfExperience"
            type="number"
            min={1}
            label={intl.formatMessage({
              id: 'EditListingPoliciesForm.EditListingPoliciesForm.yearsOfExperienceLabel',
            })}
            max={50}
            isBlock={true}
          />
          <FieldSelectModern
            className={css.policy}
            id="customOption"
            name="customOption"
            placeholder={intl.formatMessage({
              id: 'EditListingPoliciesForm.EditListingPoliciesForm.selectPlaceholder',
            })}
            label={<FormattedMessage id="EditListingPoliciesForm.customOptionLabel" />}
            options={config.custom.customOptions}
            validate={requiredFieldArrayCheckbox(
              <FormattedMessage id="EditListingPoliciesForm.customOptionRequired" />
            )}
          />
          {values?.customOption?.key === 'yes' ? (
            <div className={css.otherField}>
              <FieldCheckboxGroup
                // className={css.policy}
                id="customOptionList"
                name="customOptionList"
                options={config.custom.customProgramOptions}
                validate={requiredFieldArrayCheckbox(
                  <FormattedMessage id="EditListingPoliciesForm.EditListingPoliciesForm.customOptionRequiredMessage" />
                )}
                twoColumns={true}
              />
              {values?.customOptionList?.includes('other') ? (
                <FieldTagInput
                  // className={css.policy}
                  id="otherCustomOption"
                  name="otherCustomOption"
                  onlyUnique
                  inputProps={{
                    placeholder: intl.formatMessage({
                      id:
                        'EditListingPoliciesForm.EditListingPoliciesForm.otherCustomOptionPlaceholder',
                    }),
                  }}
                />
              ) : null}
            </div>
          ) : null}
          {/* <FieldSelectModern
            className={css.policy}
            id="languages"
            name="languages"
            label={<FormattedMessage id="EditListingPoliciesForm.languagesLabel" />}
            options={config.custom.languageOptions.filter(x => !values?.moreLanguage?.includes(x))}
            validate={requiredFieldArrayCheckbox(
              <FormattedMessage id="EditListingPoliciesForm.languagesRequired" />
            )}
          />
          <FieldCheckboxGroup
            className={css.policy}
            id="level"
            name="level"
            label={<FormattedMessage id="EditListingPoliciesForm.levelLabel" />}
            options={config.custom.levelOptions}
            validate={requiredFieldArrayCheckbox(
              <FormattedMessage id="EditListingPoliciesForm.levelRequired" />
            )}
            twoColumns={true}
          />
          <FieldSelectModern
            className={css.policy}
            id="moreLanguage"
            name="moreLanguage"
            label={<FormattedMessage id="EditListingPoliciesForm.morelanguageLabel" />}
            options={config.custom.languageOptions?.filter(
              item => item.key !== values?.languages?.key
            )}
            isMulti
            // validate={requiredFieldArrayCheckbox(
            //   <FormattedMessage id="EditListingPoliciesForm.morelanguageRequired" />
            // )}
          />
          {values?.moreLanguage?.map(item => (
            <FieldCheckboxGroup
              className={css.policy}
              id={`moreLanguageLevel${item.key}`}
              name={`moreLanguageLevel${item.key}`}
              // label={<FormattedMessage id="EditListingPoliciesForm.moreLanguagelevelLabel" />}
              label={`Level of ${item.label}`}
              options={config.custom.levelOptions}
              validate={requiredFieldArrayCheckbox(
                <FormattedMessage id="EditListingPoliciesForm.moreLanguagelevelRequired" />
              )}
              twoColumns={true}
            />
          ))} */}
          <FieldInputCounter
            className={css.policy}
            id="lessons"
            name="lessons"
            type="number"
            min={1}
            label={<FormattedMessage id="EditListingPoliciesForm.lessonsLabel" />}
            subHeading={<FormattedMessage id="EditListingPoliciesForm.lessonsSubHeading" />}
            max={6}
            isBlock={true}
          />
          {lessonValidation ? (
            <p className={css.error}>
              {intl.formatMessage({
                id: 'EditListingPoliciesForm.EditListingPoliciesForm.minLessonMaxLessonValidation',
              })}
            </p>
          ) : null}
          <FieldInputCounter
            className={css.policy}
            id="min_lessons"
            name="min_lessons"
            type="number"
            min={1}
            label={
              <FormattedMessage id="EditListingPoliciesForm.EditListingPoliciesForm.minLessons" />
            }
            max={6}
            isBlock={true}
          />
          <FieldSelectModern
            className={css.policy}
            id="qualifiedTeacher"
            name="qualifiedTeacher"
            label={<FormattedMessage id="EditListingPoliciesForm.qualifiedTeacherLabel" />}
            placeholder={intl.formatMessage({
              id: 'EditListingPoliciesForm.EditListingPoliciesForm.selectPlaceholder',
            })}
            options={config.custom.qualifiedTeacherOptions}
            validate={requiredFieldArrayCheckbox(
              <FormattedMessage id="EditListingPoliciesForm.qualifiedTeacherRequired" />
            )}
          />
          {values?.qualifiedTeacher?.key === 'yes' ? (
            <div className={css.otherField}>
              <FieldCheckboxGroup
                id="qualification"
                name="qualification"
                options={config.custom.qualificationOptions}
                validate={requiredFieldArrayCheckbox(
                  <FormattedMessage id="EditListingPoliciesForm.EditListingPoliciesForm.qualificationReq" />
                )}
              />
              {values?.qualification?.includes('other') ? (
                <>
                  <label className={css.label}></label>
                  <FieldTagInput
                    // className={css.policy}
                    id="otherQualification"
                    name="otherQualification"
                    onlyUnique
                    inputProps={{
                      placeholder: intl.formatMessage({
                        id:
                          'EditListingPoliciesForm.EditListingPoliciesForm.qualificationPlaceholder',
                      }),
                    }}
                  />
                </>
              ) : null}
            </div>
          ) : null}

          <FieldCheckboxGroup
            className={css.policy}
            id="teachingAvailability"
            name="teachingAvailability"
            label={intl.formatMessage({
              id: 'EditListingPoliciesForm.EditListingPoliciesForm.teachingAvailabilityLabel',
            })}
            options={config.custom.teachingAvailability}
            validate={requiredFieldArrayCheckbox(
              <FormattedMessage id="EditListingPoliciesForm.EditListingPoliciesForm.teachingAvailabilityRequired" />
            )}
            twoColumns={true}
          />
          <div className={css.title}>
            <p className={css.note}>
              {intl.formatMessage({
                id: 'EditListingPoliciesForm.EditListingPoliciesForm.noteSectionPrice',
              })}
            </p>
            {/* <label className={css.label}>
              {<FormattedMessage id="EditListingPoliciesForm.activitiesLabel" />}
            </label>
            <FieldTagInput
              className={css.activities}
              name="activities"
              id="activities"
              subHeading={<FormattedMessage id="EditListingPoliciesForm.activitiesSubHeading" />}
              onlyUnique
              inputProps={{
                placeholder: intl.formatMessage({
                  id: 'EditListingPoliciesForm.EditListingPoliciesForm.activitiesPlaceholder',
                }),
              }}
        
            /> */}
          </div>
          {/* {category == 'category1' ? (
            <FieldSelectModern
              className={css.policy}
              id="stay"
              name="stay"
              label="Stay"
              options={config.custom.stayOptions}
              validate={requiredFieldArrayCheckbox('Stay is required')}
            />
          ) : null}
          <FieldSelectModern
            className={css.policy}
            id="type"
            name="type"
            label="Type of place"
            options={config.custom.typeOfPlaceOptions}
            validate={requiredFieldArrayCheckbox('Type of place is required')}
          /> */}
          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingPoliciesFormComponent.defaultProps = {
  selectedPlace: null,
  updateError: null,
};

EditListingPoliciesFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  selectedPlace: propTypes.place,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(EditListingPoliciesFormComponent);
