import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage, convertLabel } from '../../util/reactIntl';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ensureOwnListing } from '../../util/data';
import { ListingLink, useInitialValueHook } from '../../components';
import { EditListingPoliciesForm } from '../../forms';

import css from './EditListingPoliciesPanel.module.css';
import config from '../../config';

const EditListingPoliciesPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
    sectionCategoryLabel,
    intl,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { publicData, title } = currentListing.attributes;
  const { category } = publicData ?? {};
  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingPoliciesPanel.title"
      values={{ listingTitle: <ListingLink listing={listing} /> }}
    />
  ) : (
    <FormattedMessage id="EditListingPoliciesPanel.createListingTitle" />
  );

  const fieldArrayData = data => {
    const result = data?.map(item => ({ language: item.language.key, level: item.level.key }));
    return result;
  };
  const fieldArrayDataLevel = data => {
    // console.log('data here', data);
    const result = data?.map(item => ({ language: item.language.key, level: item.level }));
    return result;
  };
  const languageTaughtLevels = data => {
    let result = [];
    data?.map(item => item.level?.map(item1 => result.push(item1?.key)));
    return result;
  };

  const normalizeFieldArrayData = data => {
    const result = data?.map(item => ({
      language: convertLabel(config.custom.languageOptions, intl).find(
        item1 => item1.key === item.language
      ),
      level: convertLabel(config.custom.levelOptions, intl).find(item1 => item1.key === item.level),
    }));
    return result;
  };
  const normalizeFieldArrayDataLevel = data => {
    const result = data?.map(item => ({
      language: convertLabel(config.custom.languageOptions, intl).find(
        item1 => item1.key === item.language
      ),
      level: item.level,
    }));
    return result;
  };
  const normalizeData = (data, config) =>
    data?.map(item => config?.find(configItem => configItem.key === item));
  const normalizeMoreLanguageLevel = (languages, value) => {
    let result;
    languages?.map(
      item => (result = { ...result, [`${item.key}Level`]: value[`moreLanguageLevel${item.key}`] })
    );
    return result;
  };
  const initialValuesOfMoreLanguages = () => {
    let result;

    publicData?.moreLanguage?.map(
      item =>
        (result = {
          ...result,
          [`moreLanguageLevel${item}`]: publicData.moreLanguageLevel[item + 'Level'],
        })
    );
    return result;
  };
  const oldInitialValues = {
    ...initialValuesOfMoreLanguages(),
    languages: convertLabel(config.custom.languageOptions, intl).find(
      item => item.value === publicData?.languages
    ),
    level: publicData.level,
    lessons: publicData.lessons ? publicData.lessons : 1,
    min_lessons: publicData.min_lessons ? publicData.min_lessons : 1,
    yearsOfExperience: publicData.yearsOfExperience ? publicData.yearsOfExperience : 0,
    customOption: convertLabel(config.custom.customOptions, intl).find(
      item => item.value === publicData?.customOption
    ),
    qualifiedTeacher: convertLabel(config.custom.qualifiedTeacherOptions, intl).find(
      item => item.value === publicData?.qualifiedTeacher
    ),
    moreLanguage: normalizeData(
      publicData.moreLanguage,
      convertLabel(config.custom.languageOptions, intl)
    ),
    moreLanguageLevel: publicData.moreLanguageLevel,
    activities: publicData.activities ? publicData?.activities : [],
    languages_spoken: publicData.languages_spoken
      ? normalizeFieldArrayData(publicData.languages_spoken)
      : [
          {
            language: '',
            level: '',
          },
        ],
    languages_taught: publicData?.languages_taught
      ? normalizeFieldArrayDataLevel(publicData.languages_taught)
      : [
          {
            language: '',
            level: '',
          },
        ],
    otherQualification: publicData.otherQualification ? publicData?.otherQualification : [],
    otherCustomOption: publicData.otherCustomOption ? publicData?.otherCustomOption : [],
    qualification: publicData.qualification ? publicData?.qualification : [],
    customOptionList: publicData.customOptionList ? publicData?.customOptionList : [],
    teachingAvailability: publicData.teachingAvailability,
  };
  const { initialValues, updateInitialValues } = useInitialValueHook(oldInitialValues);
  return (
    <div className={classes}>
      {sectionCategoryLabel}
      <h1 className={css.title}>{panelTitle}</h1>
      <EditListingPoliciesForm
        className={css.form}
        publicData={publicData}
        initialValues={initialValues}
        onSubmit={values => {
          const {
            languages,
            level,
            lessons,
            customOption,
            qualifiedTeacher,
            moreLanguage,
            moreLanguageLevel,
            activities,
            languages_spoken,
            languages_taught,
            qualification,
            otherQualification,
            yearsOfExperience,
            customOptionList,
            otherCustomOption,
            teachingAvailability,
            min_lessons,
          } = values;

          const updateValues = {
            title: title ? title : 'Draft',
            publicData: {
              languages_spoken: fieldArrayData(languages_spoken),
              languages_taught: fieldArrayDataLevel(languages_taught),
              // languages: languages.key,
              level: languageTaughtLevels(languages_taught),
              lessons,
              min_lessons,
              customOption: customOption.key,
              qualifiedTeacher: qualifiedTeacher.key,
              // moreLanguage: moreLanguage.map(item => item.key),
              // moreLanguageLevel:
              //   moreLanguage?.length > 0 ? normalizeMoreLanguageLevel(moreLanguage, values) : null,
              // moreLanguageLevel: moreLanguage?.length > 0 ? moreLanguageLevel : null,
              activities,
              qualification,
              otherQualification: qualification?.includes('other') ? otherQualification : null,
              yearsOfExperience,
              customOptionList,
              otherCustomOption: customOptionList?.includes('other') ? otherCustomOption : null,
              teachingAvailability,
            },
          };
          updateInitialValues({
            ...values,
            ...updateValues,
            languages,
            level,
            lessons,
            customOption,
            qualifiedTeacher,
            moreLanguage,
            moreLanguageLevel,
            activities,
            min_lessons,
          });
          onSubmit(updateValues);
        }}
        onChange={onChange}
        disabled={disabled}
        ready={ready}
        saveActionMsg={submitButtonText}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        fetchErrors={errors}
        category={category}
      />
    </div>
  );
};

const { func, object, string, bool } = PropTypes;

EditListingPoliciesPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
};

EditListingPoliciesPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingPoliciesPanel;
