import React from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { maxLength, required, composeValidators } from '../../util/validators';
import {
  Form,
  Button,
  FieldTextInput,
  FieldInputCounter,
  SecondaryButton,
  TranslateText,
} from '../../components';

import css from './EditListingDescriptionForm.module.css';

const TITLE_MAX_LENGTH = 90;
const DESCRIPTION_MAX_LENGTH = 1000;

const EditListingDescriptionFormComponent = props => (
  <FinalForm
    {...props}
    render={formRenderProps => {
      const {
        categories,
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        values,
        form,
        isLanguageEnglish,
      } = formRenderProps;

      // console.log('values', values);  /
      const titleMessage = intl.formatMessage({ id: 'EditListingDescriptionForm.title' });
      const titlePlaceholderMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.titlePlaceholder',
      });
      const titleRequiredMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.titleRequired',
      });
      const maxLengthMessage = intl.formatMessage(
        { id: 'EditListingDescriptionForm.maxLength' },
        {
          maxLength: TITLE_MAX_LENGTH,
        }
      );
      const maxLengthDescriptionMessage = intl.formatMessage(
        { id: 'EditListingDescriptionForm.maxLength' },
        {
          maxLength: DESCRIPTION_MAX_LENGTH,
        }
      );
      const descriptionMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.description',
      });
      const descriptionPlaceholderMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.descriptionPlaceholder',
      });
      const maxLength60Message = maxLength(maxLengthMessage, TITLE_MAX_LENGTH);
      const descriptionMaxLength = maxLength(maxLengthDescriptionMessage, DESCRIPTION_MAX_LENGTH);
      const descriptionRequiredMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.descriptionRequired',
      });

      const { updateListingError, createListingDraftError, showListingsError } = fetchErrors || {};
      const errorMessageUpdateListing = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDescriptionForm.updateFailed" />
        </p>
      ) : null;

      // This error happens only on first tab (of EditListingWizard)
      const errorMessageCreateListingDraft = createListingDraftError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDescriptionForm.createListingDraftError" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDescriptionForm.showListingFailed" />
        </p>
      ) : null;

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessageCreateListingDraft}
          {errorMessageUpdateListing}
          {errorMessageShowListing}
          {isLanguageEnglish ? (
            <div>
              <FieldTextInput
                id="title"
                name="title"
                className={css.title}
                type="textarea"
                label={titleMessage}
                placeholder={titlePlaceholderMessage}
                // maxLength={TITLE_MAX_LENGTH}
                validate={composeValidators(required(titleRequiredMessage), maxLength60Message)}
                autoFocus
              />
              <TranslateText
                form={form}
                value={values?.es_title}
                fromKey="es_title"
                toKey="title"
                target="en"
                id="title"
              />

              <FieldTextInput
                id="es_title"
                name="es_title"
                className={css.title}
                type="textarea"
                label={intl.formatMessage({
                  id: 'EditListingDescriptionForm.EditListingDescriptionForm.spanishTitle',
                })}
                placeholder={intl.formatMessage({
                  id: 'EditListingDescriptionForm.EditListingDescriptionForm.spanishPlaceholder',
                })}
                validate={composeValidators(required(titleRequiredMessage), maxLength60Message)}
              />
              <TranslateText
                form={form}
                value={values?.title}
                fromKey="title"
                toKey="es_title"
                target="es"
                id="es_title"
              />

              <FieldTextInput
                id="description"
                name="description"
                className={css.description}
                type="textarea"
                label={descriptionMessage}
                placeholder={descriptionPlaceholderMessage}
                validate={composeValidators(
                  required(descriptionRequiredMessage),
                  descriptionMaxLength
                )}
              />
              <TranslateText
                form={form}
                value={values?.es_description}
                fromKey="es_description"
                toKey="description"
                target="en"
                id="description"
              />
              <FieldTextInput
                id="es_description"
                name="es_description"
                className={css.description}
                type="textarea"
                label={intl.formatMessage({
                  id:
                    'EditListingDescriptionForm.EditListingDescriptionForm.spanishDescriptionLabel',
                })}
                placeholder={intl.formatMessage({
                  id:
                    'EditListingDescriptionForm.EditListingDescriptionForm.descriptionSpanishPLaceholder',
                })}
                validate={composeValidators(
                  required(descriptionRequiredMessage),
                  descriptionMaxLength
                )}
              />
              <TranslateText
                form={form}
                value={values?.description}
                fromKey="description"
                toKey="es_description"
                target="es"
                id="es_description"
              />
            </div>
          ) : (
            <div>
              <FieldTextInput
                id="es_title"
                name="es_title"
                className={css.title}
                type="textarea"
                autoFocus
                label={intl.formatMessage({
                  id: 'EditListingDescriptionForm.EditListingDescriptionForm.spanishTitle',
                })}
                placeholder={titlePlaceholderMessage}
                // placeholder={intl.formatMessage({
                //   id: 'EditListingDescriptionForm.EditListingDescriptionForm.spanishPlaceholder',
                // })}
                validate={composeValidators(required(titleRequiredMessage), maxLength60Message)}
              />
              <TranslateText
                form={form}
                value={values?.title}
                fromKey="title"
                toKey="es_title"
                target="es"
                id="es_title"
              />
              <FieldTextInput
                id="title"
                name="title"
                className={css.title}
                type="textarea"
                placeholder={intl.formatMessage({
                  id: 'EditListingDescriptionForm.EditListingDescriptionForm.spanishPlaceholder',
                })}
                label={titleMessage}
                // placeholder={titlePlaceholderMessage}
                // maxLength={TITLE_MAX_LENGTH}
                validate={composeValidators(required(titleRequiredMessage), maxLength60Message)}
              />
              <TranslateText
                form={form}
                value={values?.es_title}
                fromKey="es_title"
                toKey="title"
                target="en"
                id="title"
              />
              <FieldTextInput
                id="es_description"
                name="es_description"
                className={css.description}
                type="textarea"
                label={intl.formatMessage({
                  id:
                    'EditListingDescriptionForm.EditListingDescriptionForm.spanishDescriptionLabel',
                })}
                placeholder={descriptionPlaceholderMessage}
                // placeholder={intl.formatMessage({
                //   id:
                //     'EditListingDescriptionForm.EditListingDescriptionForm.descriptionSpanishPLaceholder',
                // })}
                validate={composeValidators(
                  required(descriptionRequiredMessage),
                  descriptionMaxLength
                )}
              />
              <TranslateText
                form={form}
                value={values?.description}
                fromKey="description"
                toKey="es_description"
                target="es"
                id="es_description"
              />
              <FieldTextInput
                id="description"
                name="description"
                className={css.description}
                type="textarea"
                label={descriptionMessage}
                placeholder={intl.formatMessage({
                  id:
                    'EditListingDescriptionForm.EditListingDescriptionForm.descriptionSpanishPLaceholder',
                })}
                // placeholder={descriptionPlaceholderMessage}
                validate={composeValidators(
                  required(descriptionRequiredMessage),
                  descriptionMaxLength
                )}
              />
              <TranslateText
                form={form}
                value={values?.es_description}
                fromKey="es_description"
                toKey="description"
                target="en"
                id="description"
              />
            </div>
          )}
          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingDescriptionFormComponent.defaultProps = { className: null, fetchErrors: null };

EditListingDescriptionFormComponent.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  categories: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ),
};

export default compose(injectIntl)(EditListingDescriptionFormComponent);
