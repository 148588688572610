import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ListingLink } from '../../components';
import { EditListingPricingForm } from '../../forms';
import { ensureOwnListing } from '../../util/data';
import { types as sdkTypes } from '../../util/sdkLoader';
import config from '../../config';
import moment from 'moment';

import css from './EditListingPricingPanel.module.css';
import useInitialValues from '../useInitialValueHook/useInitialValueHook';

const { Money } = sdkTypes;

const EditListingPricingPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
    sectionCategoryLabel,
    isLanguageEnglish,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { price, publicData } = currentListing.attributes;
  const { payment_method, additionalPrices: additionalPricesPub = [] } = publicData;
  const additionalPrices = (additionalPricesPub ?? [])
    .map(item => ({
      [item.fieldname]: item.selected,
      [`${item.fieldname}_field`]: new Money(item[`${item.fieldname}_field`], config.currency),
    }))
    .reduce((acc, item) => ({ ...acc, ...item }), {});

  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingPricingPanel.title"
      values={{ listingTitle: <ListingLink listing={listing} /> }}
    />
  ) : (
    <FormattedMessage
      id="EditListingPricingPanel.createListingTitle"
      values={{ listingTitle: <ListingLink listing={listing} /> }}
    />
  );

  const priceCurrencyValid = price instanceof Money ? price.currency === config.currency : true;

  const oldInitialValues = {
    price,
    ...additionalPrices,
    payment_method,
    hr_extra_session: publicData?.additionalPrices?.find(
      item => item.fieldname === 'hr_extra_session'
    )
      ? new Money(publicData?.additionalPrices?.[0]?.hr_extra_session_field, config.currency)
      : null,
    activities: publicData?.activities?.map(item => ({
      name: item?.name,
      price: new Money(item?.price, config.currency),
      hours: item?.hours,
      es_name: item?.es_name,
    })),
    datePriceArray:
      publicData?.datePriceArray?.map(each => {
        return {
          ...each,
          price: each.price ? new Money(each.price, config.currency) : {},
          start_date: each.start_date ? new Date(each.start_date) : '',
          end_date: each.end_date ? new Date(each.end_date) : '',
        };
      }) || [],
  };

  // console.log(oldInitialValues);
  const { initialValues, updateInitialValues } = useInitialValues(oldInitialValues);
  const form = priceCurrencyValid ? (
    <EditListingPricingForm
      className={css.form}
      initialValues={initialValues}
      onSubmit={values => {
        const { price, payment_method, datePriceArray, activities, ...rest } = values;
        const activitiesArray =
          activities?.map((item, index) => {
            return {
              name: item?.name,
              price: item?.price?.amount,
              key: index + 1 * 100,
              hours: item?.hours,
              es_name: item?.es_name,
            };
          }) || [];
        const additionalPrices = config.custom.additionalPriceOptions
          .map(ad => ({
            [`${ad.name}_field`]: rest?.[`${ad.name}`]?.amount,
            [ad.name]: !!rest[ad.name],
            selected: !!rest[ad.name],
            fieldname: ad.name,
            price: rest?.[`${ad.name}`]?.amount,
          }))
          .filter(ad => ad.selected);
        console.log({ additionalPrices });
        updateInitialValues({
          ...values,
          datePriceArray: datePriceArray
            ? datePriceArray?.map(each => {
                return {
                  ...each,
                  start_date: each.start_date,
                  end_date: each.end_date,
                };
              })
            : [],
        });

        onSubmit({
          price,
          publicData: {
            additionalPrices,
            activities: activitiesArray,
            activitiesSelected: !!activities?.length,
            payment_method: 'credit_card',
          },
        });
      }}
      onChange={onChange}
      saveActionMsg={submitButtonText}
      disabled={disabled}
      ready={ready}
      updated={panelUpdated}
      updateInProgress={updateInProgress}
      fetchErrors={errors}
      isLanguageEnglish={isLanguageEnglish}
    />
  ) : (
    <div className={css.priceCurrencyInvalid}>
      <FormattedMessage id="EditListingPricingPanel.listingPriceCurrencyInvalid" />
    </div>
  );

  return (
    <div className={classes}>
      {sectionCategoryLabel}
      <h1 className={css.title}>{panelTitle}</h1>
      {form}
    </div>
  );
};

const { func, object, string, bool } = PropTypes;

EditListingPricingPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
};

EditListingPricingPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingPricingPanel;
