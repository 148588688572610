import React from 'react';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { LINE_ITEM_NIGHT, LINE_ITEM_DAY, propTypes } from '../../util/types';

import css from './BookingBreakdown.module.css';
import { types as sdkTypes } from '../../util/sdkLoader';
const { Money } = sdkTypes;

export const customerCommission = process.env.REACT_APP_CUSTOMER_COMMISSION_PERCENTAGE
  ? +process.env.REACT_APP_CUSTOMER_COMMISSION_PERCENTAGE
  : 10;

export function getFormattedMoney(intl, unitPrice, code, isProvider = false) {
  const { amount, currency } = unitPrice;
  const commissionAmount = (amount * customerCommission) / 100;
  // const total = code == 'line-item/refund' ? amount : amount + commissionAmount;
  const total = isProvider
    ? code == 'line-item/refund'
      ? amount
      : amount
    : amount + commissionAmount;
  return formatMoney(intl, new Money(total, currency));
}

const LineItemCustomerBasePriceMaybe = props => {
  const { transaction, unitType, intl } = props;
  const isNightly = unitType === LINE_ITEM_NIGHT;
  const isDaily = unitType === LINE_ITEM_DAY;
  const translationKey = isNightly
    ? 'BookingBreakdown.baseUnitNight'
    : isDaily
    ? 'BookingBreakdown.baseUnitDay'
    : 'BookingBreakdown.baseUnitQuantity';

  // Find correct line-item for given unitType prop.
  // It should be one of the following: 'line-item/night, 'line-item/day', 'line-item/units', or 'line-item/time'
  // These are defined in '../../util/types';
  const unitPurchase = transaction.attributes.lineItems.find(
    item => item.code === unitType && !item.reversal
  );

  const quantity = unitPurchase ? unitPurchase.quantity.toString() : null;
  const unitPrice = unitPurchase ? getFormattedMoney(intl, unitPurchase.unitPrice) : null;
  const total = unitPurchase ? getFormattedMoney(intl, unitPurchase.lineTotal) : null;
  return quantity && total ? (
    <div className={css.lineItem}>
      <span className={css.itemLabel}>
        <FormattedMessage id={translationKey} values={{ unitPrice, quantity }} />
      </span>
      <span className={css.itemValue}>{total}</span>
    </div>
  ) : null;
};

LineItemCustomerBasePriceMaybe.propTypes = {
  transaction: propTypes.transaction.isRequired,
  unitType: propTypes.bookingUnitType.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemCustomerBasePriceMaybe;
