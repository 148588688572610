import React, { Component, useEffect, useState } from 'react';
import { string, bool, arrayOf, array, func } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import classNames from 'classnames';
import moment from 'moment';
import config from '../../config';
import { types as sdkTypes } from '../../util/sdkLoader';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import {
  required,
  bookingDatesRequired,
  composeValidators,
  notGreaterThanLessons,
  minimumStayRequired,
  maximumStayRequired,
  allowBookingBeforeStart,
  notLessthanLessons,
} from '../../util/validators';
import { START_DATE, END_DATE } from '../../util/dates';
import { propTypes } from '../../util/types';
import {
  Form,
  IconSpinner,
  PrimaryButton,
  FieldDateRangeInput,
  FieldSelect,
  FieldCheckbox,
  FieldCheckboxGroup,
  FieldTextInput,
} from '../../components';
import EstimatedBreakdownMaybe from './EstimatedBreakdownMaybe';

import arrayMutators from 'final-form-arrays';

import debounce from 'lodash/debounce'

import css from './BookingDatesForm.module.css';
import { formatMoney } from '../../util/currency';
const { Money, UUID } = sdkTypes;
const identity = v => v;


const debouncedOnFetch = debounce(function(onFetchTransactionLineItems, params ){

  onFetchTransactionLineItems(params);

},1000)

export class BookingDatesFormComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { focusedInput: null };
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.onFocusedInputChange = this.onFocusedInputChange.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
  }

  // Function that can be passed to nested components
  // so that they can notify this component when the
  // focused input changes.
  onFocusedInputChange(focusedInput) {
    this.setState({ focusedInput });
  }

  // In case start or end date for the booking is missing
  // focus on that input, otherwise continue with the
  // default handleSubmit function.
  handleFormSubmit(e) {
    const { startDate, endDate } = e.bookingDates || {};
    if (!startDate) {
      e.preventDefault();
      this.setState({ focusedInput: START_DATE });
    } else if (!endDate) {
      e.preventDefault();
      this.setState({ focusedInput: END_DATE });
    } else {
      this.props.onSubmit(e);
    }
  }

  // When the values of the form are updated we need to fetch
  // lineItems from FTW backend for the EstimatedTransactionMaybe
  // In case you add more fields to the form, make sure you add
  // the values here to the bookingData object.
  handleOnChange(previousValues, formValues) {
    const { startDate, endDate } =
      formValues.values && formValues.values.bookingDates ? formValues.values.bookingDates : {};

    const {
      'booking-type': bookingType,
      additional_services = [],
      additional_lessons = 0,
      activities,
    } = formValues.values || {};
    const listingId = this.props.listingId;
    const isOwnListing = this.props.isOwnListing;
    // const checkAndGetPriceFromDatePriceArray = date => {
    //   const convertSelectedDate = date ? date?.date?.toISOString().split('T')[0] : null;
    //   const priceFromArr = this.props.publicData?.datePriceArray?.map(item => {
    //     const convertedStartDate = new Date(item.start_date).toISOString().split('T')[0];
    //     const convertedEndDate = item?.end_date
    //       ? new Date(item.end_date).toISOString().split('T')[0]
    //       : '';
    //     // console.log({ convertSelectedDate, convertedEndDate, convertedStartDate });
    //     if (convertSelectedDate >= convertedStartDate && convertSelectedDate < convertedEndDate) {
    //       return item?.price;
    //     }
    //   });
    //   return { isDatePrice: true, price: priceFromArr && priceFromArr[0] };
    // };
    // const priceFromDatePriceArray = checkAndGetPriceFromDatePriceArray(bookingStartDate);
    if (startDate && endDate && !this.props.fetchLineItemsInProgress) {

      const params = {
        bookingData: {
          startDate,
          endDate,
          bookingType,
          additional_services,
          additional_lessons,
          activities,
        },
        listingId,
        isOwnListing,

        // priceFromDatePriceArray,
      }

      if(previousValues.additional_lessons != formValues.values.additional_lessons){
        debouncedOnFetch(this.props.onFetchTransactionLineItems, params)
      }else{
        this.props.onFetchTransactionLineItems(params)
      }

    }
  }

  render() {
    const { rootClassName, className, price: unitPrice, ...rest } = this.props;
    const classes = classNames(rootClassName || css.root, className);

    if (!unitPrice) {
      return (
        <div className={classes}>
          <p className={css.error}>
            <FormattedMessage id="BookingDatesForm.listingPriceMissing" />
          </p>
        </div>
      );
    }
    if (unitPrice.currency !== config.currency) {
      return (
        <div className={classes}>
          <p className={css.error}>
            <FormattedMessage id="BookingDatesForm.listingCurrencyInvalid" />
          </p>
        </div>
      );
    }

    return (
      <FinalForm
        mutators={{ ...arrayMutators }}
        {...rest}
        unitPrice={unitPrice}
        initialValues={{ additional_lessons: 0 }}
        onSubmit={this.handleFormSubmit}
        render={fieldRenderProps => {
          const {
            endDatePlaceholder,
            startDatePlaceholder,
            formId,
            handleSubmit,
            intl,
            isOwnListing,
            submitButtonWrapperClassName,
            unitType,
            values,
            timeSlots,
            fetchTimeSlotsError,
            lineItems,
            fetchLineItemsInProgress,
            fetchLineItemsError,
            paymentMethods,
            listing,
            invalid,
            bufferTime,
            disabled,
            form,
            errors,
            language,
          } = fieldRenderProps;
          const [days, setDays] = useState(0);
          useEffect(() => {
            if (values?.bookingDates?.startDate && values?.bookingDates?.endDate) {
              //calculate number of days
              const startDate = values?.bookingDates?.startDate;
              const endDate = values?.bookingDates?.endDate;
              const diffTime = Math.abs(endDate - startDate);
              const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
              setDays(diffDays);
            }
          }, [values]);
          const isLanguageEnglish = language === 'english';
          const isError = !!errors?.additional_lessons;
          const displayName = listing?.author?.attributes?.profile?.displayName || '';
          const { startDate, endDate } = values && values.bookingDates ? values.bookingDates : {};
          const maxLessons = listing?.attributes?.publicData?.lessons;
          const minLessons = listing?.attributes?.publicData?.min_lessons || 0;
          const bookingStartLabel = intl.formatMessage({
            id: 'BookingDatesForm.bookingStartTitle',
          });
          const bookingEndLabel = intl.formatMessage({
            id: 'BookingDatesForm.bookingEndTitle',
          });
          const requiredMessage = intl.formatMessage({
            id: 'BookingDatesForm.requiredDate',
          });
          const startDateErrorMessage = intl.formatMessage({
            id: 'FieldDateRangeInput.invalidStartDate',
          });
          const endDateErrorMessage = intl.formatMessage({
            id: 'FieldDateRangeInput.invalidEndDate',
          });
          const timeSlotsError = fetchTimeSlotsError ? (
            <p className={css.sideBarError}>
              <FormattedMessage id="BookingDatesForm.timeSlotsError" />
            </p>
          ) : null;

          // This is the place to collect breakdown estimation data.
          // Note: lineItems are calculated and fetched from FTW backend
          // so we need to pass only booking data that is needed otherwise
          // If you have added new fields to the form that will affect to pricing,
          // you need to add the values to handleOnChange function
          const bookingData =
            startDate && endDate
              ? {
                  unitType,
                  startDate,
                  endDate,
                }
              : null;

          const cashless = values?.['booking-type'] == 'cash_on_arrival' ? true : false;

          const submitDisabled =
            invalid || disabled || !startDate || !endDate || fetchLineItemsInProgress;
          // || !values?.['booking-type'];

          const showEstimatedBreakdown =
            bookingData && lineItems && !fetchLineItemsInProgress && !fetchLineItemsError;

          const bookingInfoMaybe = showEstimatedBreakdown ? (
            <div className={css.priceBreakdownContainer}>
              <h3 className={css.priceBreakdownTitle}>
                <FormattedMessage id="BookingDatesForm.priceBreakdownTitle" />
              </h3>
              <EstimatedBreakdownMaybe
                bookingData={bookingData}
                lineItems={lineItems}
                cashless={cashless}
              />
            </div>
          ) : null;

          const loadingSpinnerMaybe = fetchLineItemsInProgress ? (
            <IconSpinner className={css.spinner} />
          ) : null;

          const bookingTypeDisabled = !startDate || !endDate;

          const bookingInfoErrorMaybe = fetchLineItemsError ? (
            <span className={css.sideBarError}>
              <FormattedMessage id="BookingDatesForm.fetchLineItemsError" />
            </span>
          ) : null;

          const dateFormatOptions = {
            weekday: 'short',
            month: 'short',
            day: 'numeric',
          };

          const now = moment();
          const today = now.startOf('day').toDate();
          const tomorrow = now
            .startOf('day')
            .add(1, 'days')
            .toDate();
          const startDatePlaceholderText =
            startDatePlaceholder || intl.formatDate(today, dateFormatOptions);
          const endDatePlaceholderText =
            endDatePlaceholder || intl.formatDate(tomorrow, dateFormatOptions);
          const submitButtonClasses = classNames(
            submitButtonWrapperClassName || css.submitButtonWrapper
          );

          const additionalPrices = listing.attributes.publicData?.additionalPrices ?? [];
          const activities = listing.attributes.publicData?.activities ?? [];
          const isIncluded = key => additionalPrices.find(ap => ap.fieldname == key && ap.selected);
          const minimumStay = listing.attributes.publicData?.min_stay || 1;
          const maximumStay = listing.attributes.publicData?.max_stay || 120;
          // const bufferTime = listing.attributes.publicData?.buffer_time;
          // console.log('activities', activities);
          const priceOptions = config.custom.additionalPriceOptions
            .map(v => ({ ...v, key: v.id }))
            .filter(v => isIncluded(v.key));

          return (
            <Form onSubmit={handleSubmit} className={classes} enforcePagePreloadFor="CheckoutPage">
              {timeSlotsError}
              <FormSpy
                subscription={{ values: true }}
                onChange={values => {
                  this.handleOnChange(fieldRenderProps.values, values);
                }}
              />

              <FieldDateRangeInput
                className={css.bookingDates}
                name="bookingDates"
                unitType={unitType}
                startDateId={`${formId}.bookingStartDate`}
                startDateLabel={bookingStartLabel}
                startDatePlaceholderText={startDatePlaceholderText}
                endDateId={`${formId}.bookingEndDate`}
                endDateLabel={bookingEndLabel}
                endDatePlaceholderText={endDatePlaceholderText}
                focusedInput={this.state.focusedInput}
                onFocusedInputChange={this.onFocusedInputChange}
                format={identity}
                timeSlots={timeSlots}
                bufferTime={bufferTime}
                useMobileMargins
                validate={composeValidators(
                  required(requiredMessage),
                  bookingDatesRequired(startDateErrorMessage, endDateErrorMessage),
                  minimumStayRequired(
                    <FormattedMessage
                      id="BookingDatesForm.BookingDatesForm.minMaxStay"
                      values={{
                        displayName: displayName,
                        minimumStay: minimumStay,
                        maximumStay: maximumStay,
                      }}
                    />,
                    minimumStay
                  ),
                  maximumStayRequired(
                    <FormattedMessage
                      id="BookingDatesForm.BookingDatesForm.minMaxStay"
                      values={{
                        displayName: displayName,
                        minimumStay: minimumStay,
                        maximumStay: maximumStay,
                      }}
                    />,
                    maximumStay
                  )
                  // allowBookingBeforeStart(
                  //   `You can book this listing ${bufferTime} days before start date`,
                  //   bufferTime
                  // )
                )}
                disabled={fetchLineItemsInProgress}
              />

              <div className={css.bookingTypesContainer}>
                {/* {paymentMethods.length > 0 ? (
                  <FieldSelect
                    label="Booking type"
                    disabled={bookingTypeDisabled}
                    id="booking-type"
                    name="booking-type"
                  >
                    <option value="" selected hidden>
                      Select Booking type...
                    </option>
                    {config.custom.paymentMethods
                      .filter(t => paymentMethods.includes(t.key))
                      .map(t => (
                        <option key={t.key} value={t.key}>
                          {t.label}
                        </option>
                      ))}
                  </FieldSelect>
                ) : (
                  <p className={css.error}>
                    This listing can not be booked as the author has not selected any payment
                    method.
                  </p>
                )} */}
                <div className={css.container}>
                  {additionalPrices.length > 0 && (
                    <div className={css.additionalPrices}>
                      <FieldTextInput
                        id="additional_lessons"
                        name="additional_lessons"
                        label={intl.formatMessage({
                          id: 'BookingDatesForm.BookingDatesForm.additionLessonsLabel',
                        })}
                        type="number"
                        onClick={e => {
                          e.target.focus();
                        }}
                        onMouseUp={e => {
                          e.target.blur();
                        }}
                        // min="0.0"
                        validate={composeValidators(
                          notGreaterThanLessons(
                            <FormattedMessage
                              id="BookingDatesForm.BookingDatesForm.requiredMessage"
                              values={{ maxLessons: maxLessons * days }}
                            />,
                            maxLessons * days
                          ),
                          notLessthanLessons(
                            <FormattedMessage
                              id="BookingDatesForm.BookingDatesForm.minLessonRequired"
                              values={{ minLessons: minLessons * days }}
                            />,
                            minLessons * days
                          )
                        )}
                      />
                      {/* <FieldCheckboxGroup
                      id="additional_services"
                      name="additional_services"
                      label="Additional services"
                      options={priceOptions}
                      // twoColumns
                    /> */}
                    </div>
                  )}
                  {activities.length > 0 && (
                    <div className={css.activities}>
                      <FieldCheckboxGroup
                        id="activities"
                        name="activities"
                        isBooking={true}
                        label={intl.formatMessage({
                          id: 'BookingDatesForm.BookingDatesForm.activitiesLabel',
                        })}
                        options={activities?.map(item => ({
                          key: item?.key,
                          label: (
                            <div className={css.activityContainer}>
                              <div className={css.activityName}>
                                {isLanguageEnglish ? item?.name : item?.es_name || item?.name}{' '}
                                {item.hours
                                  ? item.hours > 1
                                    ? `(${item?.hours} hours)`
                                    : item.hours
                                    ? `(${item?.hours} hour)`
                                    : null
                                  : null}
                              </div>
                              <div className={css.activityPrice}>
                                {formatMoney(
                                  intl,
                                  new Money(item?.price + (item?.price * 10) / 100, config.currency)
                                )}
                              </div>
                            </div>
                          ),
                        }))}
                        // twoColumns
                      />
                    </div>
                  )}
                </div>
              </div>

              {isError ? null : bookingInfoMaybe}
              {loadingSpinnerMaybe}
              {bookingInfoErrorMaybe}

              <p className={css.smallPrint}>
                <FormattedMessage
                  id={
                    isOwnListing
                      ? 'BookingDatesForm.ownListing'
                      : 'BookingDatesForm.youWontBeChargedInfo'
                  }
                />
              </p>
              <div className={submitButtonClasses}>
                <PrimaryButton type="submit" disabled={submitDisabled}>
                  <FormattedMessage id="BookingDatesForm.requestToBook" />
                </PrimaryButton>
              </div>
            </Form>
          );
        }}
      />
    );
  }
}

BookingDatesFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  submitButtonWrapperClassName: null,
  price: null,
  isOwnListing: false,
  startDatePlaceholder: null,
  endDatePlaceholder: null,
  timeSlots: null,
  lineItems: null,
  fetchLineItemsError: null,
};

BookingDatesFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  submitButtonWrapperClassName: string,

  unitType: propTypes.bookingUnitType.isRequired,
  price: propTypes.money,
  isOwnListing: bool,
  timeSlots: arrayOf(propTypes.timeSlot),

  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,

  // from injectIntl
  intl: intlShape.isRequired,

  // for tests
  startDatePlaceholder: string,
  endDatePlaceholder: string,
};

const BookingDatesForm = compose(injectIntl)(BookingDatesFormComponent);
BookingDatesForm.displayName = 'BookingDatesForm';

export default BookingDatesForm;
